<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="22">
          <el-form :inline="true" size="small">
            <el-form-item label="产品名称">
              <el-input
                v-model="Name"
                placeholder="输入产品名称、别名搜索"
                clearable
                @clear="handleSearch"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
            <el-form-item label="产品分类">
              <el-cascader
                @change="getSearchResult"
                @clear="getSearchResult"
                :options="classifyList"
                :show-all-levels="true"
                clearable
                filterable
                :props="cascaderProps"
                v-model="PCategoryID"
                placeholder="请选择产品分类"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="产品品牌">
              <el-select
                v-model="ProductBrandID"
                clearable
                filterable
                placeholder="请选择产品品牌"
                :default-first-option="true"
                @change="handleSearch"
                @clear="handleSearch"
              >
                <el-option
                  v-for="item in productBrandList"
                  :key="item.ID"
                  :label="item.Name"
                  :value="item.ID"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select
                v-model="Active"
                placeholder="请选择有效性"
                clearable
                @change="handleSearch"
              >
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="handleSearch"
                v-prevent-click
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2" class="text_right">
          <el-button
            type="primary"
            size="small"
            @click="showAddDialog"
            v-prevent-click
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="tableData">
        <el-table-column prop="Name" label="产品名称"></el-table-column>
        <el-table-column prop="Alias" label="产品别名"></el-table-column>
        <el-table-column
          prop="ProductBrandName"
          label="产品品牌"
        ></el-table-column>
        <el-table-column
          prop="PCategoryName"
          label="产品分类"
        ></el-table-column>
        <el-table-column
          prop="Specification"
          label="产品规格"
        ></el-table-column>
        <el-table-column prop="UnitName" label="最小包装单位"></el-table-column>
        <el-table-column
          prop="BarCode"
          label="最小包装单位条码"
        ></el-table-column>
        <el-table-column
          prop="Active"
          label="有效性"
          :formatter="formatStatus"
        ></el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="showEditDialog(scope.row, 'edit')"
              v-prevent-click
              >编辑</el-button
            >
            <!-- <el-button type="primary" size="small" @click="showEditDialog(scope.row,'show')" v-prevent-click>详情</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 text_right">
        <el-pagination
          background
          v-if="paginations.total > 0"
          @current-change="handleCurrentChange"
          :current-page.sync="paginations.page"
          :page-size="paginations.page_size"
          :layout="paginations.layout"
          :total="paginations.total"
        ></el-pagination>
      </div>
    </div>
    <!--添加、编辑弹出框-->
    <el-dialog
      title="产品资料"
      :visible.sync="AddDialogVisible"
      :close-on-click-modal="false"
      width="860px"
      @close="clearValidate('ruleForm_add')" 
    >
      <el-form
        :model="ruleForm_add"
        :rules="rules_add"
        ref="ruleForm_add"
        label-width="150px"
        size="small"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="Name">
              <el-input
                placeholder="请输入产品名称"
                v-model="ruleForm_add.Name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品别名">
              <el-input
                placeholder="请输入产品别名"
                v-model="ruleForm_add.Alias"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品分类" prop="PCategoryID">
              <el-cascader
                :options="classifyList"
                :show-all-levels="true"
                clearable
                filterable
                :props="cascaderProps"
                v-model="ruleForm_add.PCategoryID"
                placeholder="请选择产品分类"
              ></el-cascader>
              <!-- <el-select v-model="ruleForm_add.PCategoryID" placeholder="请选择产品分类" filterable>
                <el-option
                  :label="item.Name"
                  :value="item.ID"
                  v-for="(item,index) in classifyList"
                  :key="index"
                ></el-option>
              </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品规格">
              <el-input
                placeholder="请输入产品规格"
                v-model="ruleForm_add.Specification"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最小包装单位" prop="UnitID">
              <span slot="label">
                最小包装单位
                <el-popover placement="top-start" width="220" trigger="hover">
                  <p>1.门店销售使用的单位;</p>
                  <p>
                    2.比如产品采购以“箱”为单位，调拨以“盒”为单位，售卖以“片”为单位，此时设置的最小单位为“片”，其他单位维护，在“产品单位”里面维护;
                  </p>
                  <p>
                    3.注意：产品最小单位就不能修改，如果设置错误，需要把产品的有效性改成否，重新新建产品。
                  </p>
                  <el-button
                    type="text"
                    style="color: #dcdfe6"
                    icon="el-icon-info"
                    slot="reference"
                  ></el-button>
                </el-popover>
              </span>
              <el-select
                v-model="ruleForm_add.UnitID"
                :disabled="isEditInfo"
                placeholder="请选择最小包装单位"
                filterable
              >
                <el-option
                  :label="item.Name"
                  :value="item.ID"
                  v-for="(item, index) in unitList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最小包装单位条码" prop="BarCode">
              <span slot="label">
                最小包装单位条码
                <el-popover placement="top-start" width="220" trigger="hover">
                  <p>1.产品最小包装单位对应的条码。</p>
                  <p>2.如设置条码，条码必须唯一。</p>
                  <el-button
                    type="text"
                    style="color: #dcdfe6"
                    icon="el-icon-info"
                    slot="reference"
                  ></el-button>
                </el-popover>
              </span>
              <el-input
                placeholder="请输入产品最小包装单位条码"
                v-model="ruleForm_add.BarCode"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="直营店采购单价" prop="PurchasePrice">
              <el-input
                placeholder="请输入直营店采购单价"
                v-model.number="ruleForm_add.PurchasePrice"
                v-positiveNumber
              >
                <div slot="append">元</div>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品品牌" prop="ProductBrandID">
              <el-select
                v-model="ruleForm_add.ProductBrandID"
                clearable
                filterable
                placeholder="请选择产品品牌"
                :default-first-option="true"
              >
                <el-option
                  v-for="item in productBrandList"
                  :key="item.ID"
                  :label="item.Name"
                  :value="item.ID"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="加盟店采购单价" prop="FranchiseePrice">
              <el-input
                placeholder="请输入加盟店采购单价"
                v-model.number="ruleForm_add.FranchiseePrice"
                v-positiveNumber
              >
                <div slot="append">元</div>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效性" prop="Active">
              <el-radio-group v-model="ruleForm_add.Active">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="备注信息">
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入备注信息"
                v-model="ruleForm_add.Remark"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="resetAddForm" size="small" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="submitAddForm"
          size="small"
          v-prevent-click
          >保 存</el-button
        >
      </div>
    </el-dialog>
    <!--查看弹出框-->
    <el-dialog
      title="产品资料详情"
      :visible.sync="showDialogVisible"
      width="860px"
    >
      <el-form label-width="150px" size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称：">
              <span>{{ ruleForm_add.Name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品别名：">
              <span>{{ ruleForm_add.Alias }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品分类：">
              <span>{{ PCategory }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品规格：">
              <span>{{ ruleForm_add.Specification }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最小包装单位">
              <span slot="label">
                最小包装单位
                <el-popover placement="top-start" width="220" trigger="hover">
                  <p>1.门店销售使用的单位;</p>
                  <p>
                    2.比如产品采购以“箱”为单位，调拨以“盒”为单位，售卖以“片”为单位，此时设置的最小单位为“片”，其他单位维护，在“产品单位”里面维护;
                  </p>
                  <p>
                    3.注意：产品最小单位就不能修改，如果设置错误，需要把产品的有效性改成否，重新新建产品。
                  </p>
                  <el-button
                    type="text"
                    style="color: #dcdfe6"
                    icon="el-icon-info"
                    slot="reference"
                  ></el-button>
                </el-popover>
              </span>
              <span>{{ Unit }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最小包装单位条码">
              <span slot="label">
                最小包装单位条码
                <el-popover placement="top-start" width="220" trigger="hover">
                  <p>1.产品最小包装单位对应的条码。</p>
                  <p>2.如设置条码，条码必须唯一。</p>
                  <el-button
                    type="text"
                    style="color: #dcdfe6"
                    icon="el-icon-info"
                    slot="reference"
                  ></el-button>
                </el-popover>
              </span>
              <span>{{ ruleForm_add.BarCode }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="产品品牌：">
              <span>{{ ProductBrand }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="有效性：">
              <span v-if="ruleForm_add.Active">是</span>
              <span v-else>否</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注信息：">
              <span>{{ ruleForm_add.Remark }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <!--添加分类弹出框-->
    <el-dialog
      :title="dialogTitle_add"
      :visible.sync="addTypeDialogVisible"
      width="30%"
    >
      <el-form
        :model="addTypeForm"
        :rules="addTypeFormRule"
        ref="addTypeFormRule"
        label-width="120px"
        size="small"
      >
        <el-form-item
          label="分类名称"
          prop="name"
          v-if="dialogType == 'classify'"
        >
          <el-input v-model="addTypeForm.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item
          label="单位名称"
          prop="unitName"
          v-if="dialogType == 'unit'"
        >
          <el-input
            v-model="addTypeForm.unitName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetTypeForm" size="small" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="submitTypeForm"
          size="small"
          v-prevent-click
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import APIProduct from "@/api/PSI/Product/product";
import APICategory from "@/api/PSI/Product/productCategory";
import APIUnit from "@/api/PSI/Product/unit";
import productBrandAPI from "@/api/PSI/Product/productBrand";
import permission from "@/components/js/permission.js";
export default {
  name: "ProductInfo",
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     vm.isSavingCardEdit = permission.permission(
  //       to.meta.Permission,
  //       "PSI-Product-ProductInfo-Update"
  //     );
  //     vm.isSavingCardShow = permission.permission(
  //       to.meta.Permission,
  //       "PSI-Product-ProductInfo-View"
  //     );
  //     vm.isSavingCardNew = permission.permission(
  //       to.meta.Permission,
  //       "PSI-Product-ProductInfo-Create"
  //     );
  //     vm.getProductCategory();
  //     vm.getUnitList();
  //     vm.getProductBrandList();
  //   });
  // },
  data() {
    return {
      loading: false,
      Active: true,
      modalLoading: false,
      dialogVisible: false, // 批量设置弹出框状态
      showDialogVisible: false, //查看弹出层
      AddDialogVisible: false, // 添加或编辑信息弹出框状态
      addTypeDialogVisible: false, // 添加、编辑弹出框状态
      isEditInfo: false, // 是否编辑 编辑状态下 包装单位、产品条码 不可修改
      Name: "",
      PCategoryID: "",
      ProductBrandID: "",
      tableData: [], // 表格数据
      modalTitle: "新增",
      multipleSelection: [],
      commandType: "",
      dialogTitle: "", // 批量设置弹出框标题
      classifyList: [], // 分类列表
      dialogType: "", // 判断添加分类弹出框类别
      dialogTitle_add: "", // 添加分类弹出框标题
      unitList: [], // 单位列表
      routerType: "", // 添加、编辑按钮事件判断
      ruleForm: {
        classify: "",
        purpose: "",
        effectiveness: "",
      }, // 批量设置弹出框表单
      rules: {
        classify: [{ required: true, message: "请输入分类", trigger: "blur" }],
        purpose: [{ required: true, message: "请输入用途", trigger: "blur" }],
        effectiveness: [
          { required: true, message: "请输入有效性", trigger: "blur" },
        ],
      }, // 批量设置弹出框表单验证规则
      ruleForm_add: {
        Name: "",
        Alias: "",
        PCategoryID: "",
        UnitID: "",
        Specification: "",
        Active: true,
        BarCode: "",
        ProductBrandID: "",
        FranchiseePrice:"",
        Remark: "",
      }, // 添加、编辑弹出框表单数据
      rules_add: {
        PurchasePrice: [
          { required: true, message: "请输入采购价格", trigger: "blur" },
        ],
        FranchiseePrice: [
          { required: true, message: "请输入加盟商采购价格", trigger: "blur" },
        ],
        Name: [
          { required: true, message: "请输入产品名称", trigger: "blur" },
          { max: 30, message: "最多允许30个汉字(60个字符)", trigger: "blur" },
        ],

        PCategoryID: [
          { required: true, message: "请选择产品分类", trigger: "change" },
        ],
        UnitID: [
          { required: true, message: "请选择产品单位", trigger: "change" },
        ],
        Active: [
          { required: true, message: "请选择有效性", trigger: "change" },
        ],
      }, // 添加、编辑弹出框表单验证规则
      // BarCode: [{ required: true, message: "请输入产品条码", trigger: "blur" }],
      addTypeForm: {
        name: "",
        unitName: "",
      }, // 添加分类弹出框表单数据
      addTypeFormRule: {
        name: [{ required: true, message: "请输入分类名称", trigger: blur }],
        unitName: [
          { required: true, message: "请输入单位名称", trigger: blur },
        ],
      }, // 添加分类弹出框表单验证规则
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 12, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      }, //需要给分页组件传的信息
      cascaderProps: {
        value: "ID",
        label: "Name",
        children: "Child",
        emitPath: false,
        expandTrigger: "hover",
      }, // 级联选择器配置项
      productBrandList: [], // 产品品牌列表
      // isSavingCardEdit: "", //编辑权限
      // isSavingCardNew: "", //新增权限
      // isSavingCardShow: "", //查看权限
    };
  },
  computed: {
    PCategory: function () {
      if (!this.ruleForm_add.PCategoryID) return "";
      const value = this.ruleForm_add.PCategoryID.toString();
      const item = this.classifyList.filter((item) => {
        if (item.Child.length) {
          return item.Child.some((item) => item.ID.toString() == value);
        }
      });
      return (
        item[0].Name +
        "/" +
        item[0].Child.find((item) => item.ID == this.ruleForm_add.PCategoryID)
          .Name
      );
    },
    Unit: function () {
      if (!this.ruleForm_add.UnitID) return "";
      const value = this.ruleForm_add.UnitID.toString();
      const item = this.unitList.find((item) => item.ID == value);
      return item.Name;
    },
    ProductBrand: function () {
      if (!this.ruleForm_add.ProductBrandID) return "";
      const value = this.ruleForm_add.ProductBrandID.toString();
      const item = this.productBrandList.find((item) => item.ID == value);
      return item.Name;
    },
  },
  methods: {
    // 搜索
    getSearchResult() {
      var that = this;
      that.paginations.page = 1;
      that.getProductList();
    },
    // //状态显示转换
    formatStatus: function (row) {
      return row.Active ? "是" : "否";
    },
    // 数据显示
    handleSearch: function () {
      var that = this;
      that.paginations.page = 1;
      that.getProductList();
    },
    // 获取产品分类列表
    getProductCategory: function () {
      var that = this;
      that.loading = true;
      // var params = {
      //   Name: "",
      //   Active: true,
      // };
      APICategory.getValidProductCategory()
        .then((res) => {
          if (res.StateCode == 200) {
            that.classifyList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取产品列表
    getProductList: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.Name,
        PCategoryID: that.PCategoryID,
        Active: that.Active,
        PageNum: that.paginations.page,
        ProductBrandID: that.ProductBrandID,
      };
      APIProduct.getProduct(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.page_size = res.PageSize;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.getProductList();
    },
    // 新增
    showAddDialog: function () {
      var that = this;
      that.isEditInfo = false;
      that.ruleForm_add = {
        Name: "",
        Alias: "",
        PCategoryID: "",
        UnitID: "",
        Specification: "",
        Active: true,
        BarCode: "",
        ProductBrandID: "",
      };
      that.AddDialogVisible = true;
      that.routerType = "add";
    },
    // 编辑
    showEditDialog: function (row, type) {
      var that = this;
      that.isEditInfo = true;
      that.AddDialogVisible = type == "edit" ? true : false;
      that.showDialogVisible = type == "show" ? true : false;
      that.routerType = "update";
      var tableInfo = row;
      that.Id = tableInfo.ID;
      that.ruleForm_add = tableInfo;
    },
    // 表格选择事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量设置点击事件
    handleSelect(val) {
      var that = this;
      if (that.multipleSelection.length > 0) {
        that.dialogVisible = true;
        that.commandType = val;
        switch (val) {
          case "classify":
            that.dialogTitle = "分类";
            break;
          case "purpose":
            that.dialogTitle = "用途";
            break;
          case "effectiveness":
            that.dialogTitle = "有效性";
            break;
        }
      } else {
        that.$message.error("请先选择产品信息");
      }
    },
    // 批量设置弹出框确定事件
    submitForm() {
      var that = this;
      that.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          that.dialogVisible = false;
        }
      });
    },
    // 表单取消事件
    resetForm() {
      var that = this;
      that.$refs["ruleForm"].resetFields();
      that.dialogVisible = false;
    },
    // 添加、编辑部分
    // 创建产品
    createProduct: function () {
      var that = this;
      that.loading = true;
      var params = that.ruleForm_add;
      APIProduct.createProduct(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.AddDialogVisible = false;
            that.$refs["ruleForm_add"].resetFields();
            that.getProductList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 编辑产品
    updateProduct: function () {
      var that = this;
      that.loading = true;
      var params = that.ruleForm_add;
      params.ID = that.Id;
      APIProduct.updateProduct(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.AddDialogVisible = false;
            that.$refs["ruleForm_add"].resetFields();
            that.getProductList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 创建产品类别
    createProductCategory: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.addTypeForm.name,
      };
      APICategory.createProductCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.addTypeDialogVisible = false;
            that.getProductCategory();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取包装单位列表
    getUnitList: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: "",
        Active: "",
      };
      APIUnit.getUnitAll(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.unitList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 创建包装单位
    createUnit: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.addTypeForm.unitName,
      };
      APIUnit.createUnit(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.addTypeDialogVisible = false;
            that.getUnitList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 表单点击保存按钮
    submitAddForm() {
      var that = this;
      this.$refs["ruleForm_add"].validate((valid) => {
        if (valid) {
          if (that.routerType == "update") {
            that.updateProduct();
          } else if (that.routerType == "add") {
            that.createProduct();
          }
        }
      });
    },
    //移除表单校验结果
    clearValidate(ref){
      this.$refs[ref].clearValidate();
    },
    // 表单点击取消按钮
    resetAddForm() {
      var that = this;
      that.AddDialogVisible = false;
    },
    // 添加分类表单确认按钮
    submitTypeForm() {
      var that = this;
      this.$refs["addTypeFormRule"].validate((valid) => {
        if (valid) {
          if (that.dialogType == "classify") {
            that.createProductCategory();
          } else {
            that.createUnit();
          }
        }
      });
    },
    // 添加分类表单取消按钮
    resetTypeForm() {
      var that = this;
      that.$refs["addTypeFormRule"].resetFields();
      that.addTypeDialogVisible = false;
    },
    // 添加分类
    addClassify(type) {
      var that = this;
      that.dialogType = type;
      if (type == "classify") {
        that.dialogTitle_add = "添加分类";
      } else {
        that.dialogTitle_add = "添加单位";
      }
      that.addTypeDialogVisible = true;
    },
    // 获取产品品牌列表
    getProductBrandList: function () {
      let that = this;
      var params = {
        Name: "",
        Active: true,
      };
      productBrandAPI
        .getProductBrandList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.productBrandList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
  },
  mounted() {
    var that = this;
    // that.isSavingCardEdit = permission.permission(
    //   that.$route.meta.Permission,
    //   "PSI-Product-ProductInfo-Update"
    // );
    // that.isSavingCardShow = permission.permission(
    //   that.$route.meta.Permission,
    //   "PSI-Product-ProductInfo-View"
    // );
    // that.isSavingCardNew = permission.permission(
    //   that.$route.meta.Permission,
    //   "PSI-Product-ProductInfo-Create"
    // );
    that.getProductList();
    that.getProductCategory();
    that.getUnitList();
    that.getProductBrandList();
  },
};
</script>

<style lang="scss">
</style>


